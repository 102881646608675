<template>
  <div>
    <b-pagination-nav
      :link-gen="linkGen"
      v-model="pagination.pageCurrent"
      :hide-goto-end-buttons="false"
      :hide-ellipsis="true"
      first-text="First"
      prev-text="Previous"
      next-text="Next"
      last-text="Last"
      align="right"
      limit=5
      @input="onChange"
      :number-of-pages="pagination.pageCount" use-router></b-pagination-nav>
  </div>
</template>
<script>
import { BPaginationNav } from 'bootstrap-vue';

export default {
  components: {
    BPaginationNav,
  },
  props: {
    pagination: {
      type: Object,
      required: true,
    },
  },
  methods: {
    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}&per-page=${this.pagination.perPage}`;
    },
    onChange() {
      this.$emit('changepage');
    },
  },
};
</script>
